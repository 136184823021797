export function html(literals, ...vars) {
  let raw = literals.raw;
  let result = '';
  let i = 1;
  let len = arguments.length;
  let str;
  let variable;

  while (i < len) {
    str = raw[i - 1];
    variable = vars[i - 1];
    result += str + variable;
    i++;
  }

  result += raw[raw.length - 1];

  return result;
}
