export const settings = {
  selectors: {
    parent: '#sfaq .fixedContactWrapper',
    wrapper: '[data-chat-wrapper]',
    client: '[data-client]',
    toggle: '[data-toggle]',
    anchorToggle: '#chatBotContainer a',
    close: '[data-close]',
    minify: '[data-minify]',
    download: '[data-download]',
    externalToggle: '.js-toggle-chatBot',
    popupClose: '[data-popup-close]',
    popupCancel: '[data-popup-cancel]',
    chatPopupContainer: '.chatBot-container--popup',
    chatMainContainer: '.chatBot-container',
    chatHeaderTop: '[data-chatBot-top__header]',
    chatHeaderTopAgent: '[data-chatBot-top__header-agent]',
    chatWindowLoading: '[data-loading]',
    chatWindowChat: '#chatBot',
    feedbackGiveSelector: '.chatBot-container--popup.chatBot-container--feedback',
  },
  class: {
    hidden: 'active',
  },
  constants: {
    openChatParam: 'openChat=true',
  },
  state: {
    minified: false,
    realPerson: false,
    loadingHandover: false,
    realPersonName: '',
    realPersonMsg: 'ist dem Chat beigetreten.',
    handoverMsgStart: 'Der Live Chat wurde gestartet.',
    handoverMsgEnd: 'ist dem Chat beigetreten.',
    handoverMsgFail:
      'Wir konnten Sie leider nicht rechtzeitig mit einem Agenten verbinden. Bitte versuchen Sie es später noch einmal.',
    handoverMsgTechnical: 'Sie werden verbunden.',
    handoverMsgAgentFinish: 'Der Live Chat ist beendet worden.',
    config: {},
  },
  controllers: {},
};
