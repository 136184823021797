import { settings } from './_settings';
import { View } from './_view';

export class Controller {
  constructor(elem, data = {}) {
    const queryString = window.location.search;
    this._view = new View(elem, data);
    this._externalToggle = document.querySelectorAll(settings.selectors.externalToggle);
    this.listen();
    if (
      true === data.open ||
      queryString.indexOf(settings.constants.openChatParam) > 0
    ) {
      this.handleToggle();
    }
  }

  listen() {
    if (this._view.dom.toggle) {
      this._view.dom.toggle.addEventListener('click', this.handleToggle.bind(this));
    }

    if (this._externalToggle.length) {
      this._externalToggle.forEach(el => {
        el.addEventListener('click', this.handleToggle.bind(this));
      });
    }

    if (this._view.dom.anchorToggle.length) {
      this._view.dom.anchorToggle.forEach(el => {
        el.addEventListener('click', this.handleToggle.bind(this));
        // do not remove href in cms as cause CKA editor issues
        if (document.querySelector('#chatBotContainer.chatBotContainer--href')) {
          el.removeAttribute('href');
        }
      });
    }

    window.addEventListener('message', event => {
      const namespace = event.data?.namespace;
      const action = event.data?.action;
      if (!namespace || 'chatBot' !== namespace || !action) {
        return;
      }

      if ('swapBubbleIcon' === action) {
        this._view.swapBubbleIcon();
      }

      if ('close' === action) {
        this._view.toggleChat();
      }
    });
  }

  handleToggle() {
    this._view.toggleChat();
    if (!this._view.dom.client.src) {
      this._view.dom.client.src = this.getGeneratedIframeURL();
      settings.state.minified = false;
    }
  }

  handleClose() {
    settings.state.minified = false;
  }

  getGeneratedIframeURL() {
    const getBlobURL = (code, type) => {
      const blob = new Blob([code], { type });
      return URL.createObjectURL(blob);
    };
    return getBlobURL(this._view.templateIframe(), 'text/html');
  }
}
