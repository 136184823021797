import { settings } from './_settings';
import { Controller } from './_controller';

const myScriptElement = document.getElementById('chatScript');

const elem =
  document.querySelector(settings.selectors.parent) ||
  document.querySelector('[data-chat-wrapper]');

const dataAttrContainer =
  myScriptElement && myScriptElement.getAttribute('data-container');

if (dataAttrContainer) {
  settings.selectors.parent = myScriptElement.getAttribute('data-container');
  settings.selectors.wrapper = settings.selectors.parent;
}

let data = {};

let stateObj = JSON.parse(sessionStorage.getItem('chatState'));
if (stateObj !== null && stateObj.minified !== true) {
  data.open = true;
  elem.classList.remove('chatBot--blacklisted');
}

if (elem) {
  new Controller(elem, data);
}
