/**
 * {{String}} - url to FE resources universal localo and CMS as sometimes needed to pass in JS
 * ex.:https://cwde-dev.corp.vattenfall.com/resources/cwmf-de/2-4-0/
 * or local: http://c036378:9990/
 */
export const feVersion = (path = '/scripts/main.js') => {
  const scriptArray = document.getElementsByTagName('script');

  if (scriptArray.length) {
    for (let i = 0, len = scriptArray.length; i < len; i++) {
      let src = scriptArray[i].src;
      const index = src.indexOf(path);
      if (index > 0) {
        return src.substr(0, index + 1);
      }
    }
  }

  return '';
};

export default feVersion();
