import { feVersion } from '../../../../scripts/vfde/feVersion';
import { html } from '../../../../scripts/utils/html-tagged-template';
import { settings } from './_settings';

export class View {
  constructor(wrapper, data) {
    this._wrapper = wrapper;
    this._data = data;

    this.loadStyles();
    this.initDOM();
  }

  get dom() {
    return this._dom;
  }

  initDOM() {
    //if this container is present then component used no need to append chat aside btn
    if (!document.getElementById('chatBotContainer')) {
      this._wrapper.insertAdjacentHTML('afterend', this._template(this._data));
    }

    this._dom = {};
    this._dom.wrapper =
      document.querySelector(settings.selectors.wrapper) ||
      document.querySelector(settings.selectors.parent);
    this._dom.client = this._dom.wrapper.querySelector(settings.selectors.client);
    this._dom.toggle = this._dom.wrapper.querySelector(settings.selectors.toggle);
    this._dom.toggleIcon = this._dom.toggle.querySelector('i');
    this._dom.anchorToggle = this._dom.wrapper.querySelectorAll(
      settings.selectors.anchorToggle
    );
    this._dom.close = this._dom.wrapper.querySelector(settings.selectors.close);
  }

  loadStyles() {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `${feVersion('/scripts/chatBot.js')}styles/chatBot.css`;
    document.head.appendChild(link);
  }

  swapBubbleIcon() {
    this._dom.toggleIcon.classList.toggle('vf-icon-chat');
    this._dom.toggleIcon.classList.toggle('vf-icon-headset');
  }

  toggleChat() {
    this._dom.client.classList.toggle(settings.class.hidden);
    this._dom.toggle.classList.toggle('chatBot__bubble-icon--hidden');
  }

  _template() {
    return html`
      <div class="fixedContactWrapper fixedChatWrapper">
        <aside data-chat-wrapper class="external_iframe">
          <iframe data-client></iframe>
          <span data-toggle title="Chat mit Ida"
            ><i class="vf-icon vf-icon-chat"></i
          ></span>
        </aside>
      </div>
    `;
  }

  templateIframe() {
    return html`
    <!DOCTYPE html>
      <html>
        <head>
          <script src="${feVersion(
            '/scripts/chatBot.js'
          )}scripts/chat-bot-core/fix.js"></script>
          <script crossorigin="anonymous" src="${feVersion(
            '/scripts/chatBot.js'
          )}scripts/chat-bot-core/webchat.js"></script>
          <link rel="stylesheet" href="${feVersion(
            '/scripts/chatBot.js'
          )}styles/chatBot.css" type="text/css">
        </head>


        <div class="chatBot-container--popup hiddenImportant">
          <div class="chatBot-container--popup-msg">
            <img class="chatBot-top__logo" src="${feVersion(
              '/scripts/chatBot.js'
            )}images/chatQuestion.png">
            <p class="pb15 pl10 pr10">Möchten Sie den Live-Chat wirklich beenden?</p>
            <p>
              <a data-popup-close href="#" class="button button--primary">Ja, beenden</a>
            </p>
            <p>
              <a data-popup-cancel href="#" class="button button--senary">Abbrechen</a>
            </p>
          </div>
        </div>

        <div class="chatBot-container--popup chatBot-container--feedback hiddenImportant">
          <div class="chatBot-container--popup-msg hiddenH0">
            <a data-close-feedback="" class="close">
              <img src="${feVersion('/scripts/chatBot.js')}images/chat-close.png">
            </a>
            <div class="pt15" data-dynamic>
            <p>
              Möchten Sie uns <b>Feedback</b> geben?
            </p>
            </div>
            <p>
              <a data-feedback-open href="#" class="button button--primary">Feedback senden</a>
            </p>
            <p class="pb15 p_centered noSpace">
              <a data-feedback-send-no-msg href="#">Abbrechen</a>
            </p>
          </div>
        </div>

        <div class="chatBot-container">
          <div class="chatBot-top">
            <div data-chatBot-top__header class="chatBot-top__header">
              <div class="chatBot-top__header__logo-Container">
                <img class="chatBot-top__logo" src="${feVersion(
                  '/scripts/chatBot.js'
                )}images/live_chat.png">
              </div>
              <p>Digitaler Assistent</p>
            </div>
            <div data-chatBot-top__header-agent class="chatBot-top__header chatBot-top__header-agent hiddenImportant">
              <div class="chatBot-top__header__logo-Container">
                <img class="chatBot-top__logo" src="${feVersion(
                  '/scripts/chatBot.js'
                )}images/live_chat_agent.png">
              </div>
              <div>
                <h3>Vattenfall Live-Chat</h3>
                <p></p>
              </div>
            </div>
            <div>
              <a data-download class="download">
                <img src="${feVersion('/scripts/chatBot.js')}images/chat-download.png">
              </a>
              <a data-minify class="minify">
                <img src="${feVersion('/scripts/chatBot.js')}images/chat-minify.png">
              </a>
              <a data-close class="close">
                <img src="${feVersion('/scripts/chatBot.js')}images/chat-close.png">
              </a>
            </div>
          </div>
          <div class="" id="chatBot" role="main">

          </div>
          <div data-loading class="loadingHandover hiddenImportant">
            <img class="loadingImg"></img>
            <p>Live-Chat wird gestartet</p>
          </div>

        </div>
        <script defer src="${feVersion(
          '/scripts/chatBot.js'
        )}scripts/chatBotIframe.js"></script>
        </body>
      </html>`;
  }
}
